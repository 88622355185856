import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, Flex, Button, Link } from 'rebass/styled-components';
import { Label, Input, Textarea, Checkbox } from '@rebass/forms'
import Select from 'react-select'
import { connect } from 'react-redux'
import { useForm, Controller } from 'react-hook-form';
import { collection, getDocs, query, updateDoc, doc, where, arrayUnion } from 'firebase/firestore';
import { db } from '../../system/firebase/index'
import { closeModal } from '../../system/redux/reducers/modal'
import { ThemeContext } from 'styled-components'

const customSelect = (theme) => ({
    singleValue: () => ({
        color: theme.colors.grey,
    }),
    placeholder: () => ({
        color: theme.colors.grey,
    }),
    option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        border: '1px solid ' + theme.colors.grey,
        backgroundColor: (isSelected) ? theme.colors.aquaLight : theme.colors.charcoal,
        borderColor: (isFocused || isSelected) ? theme.colors.aquaMedium : theme.colors.aquaDark,
        color: (isSelected) ? theme.colors.charcoal : theme.colors.grey,
        padding: theme.space.xs,
        cursor: 'pointer',
    }),
    control: () => ({
        cursor: 'pointer',
        color: theme.colors.grey,
        backgroundColor: theme.colors.charcoal,
        borderColor: theme.colors.aquaMedium,
        borderStyle: 'solid',
        borderWidth: '1px',
        display: 'flex',
        height: '32px',
        padding: '0px',
        minWidth: '90px',
    }),
    menuList: () => ({
        padding: '0px',
    }),
    dropdownIndicator: () => ({
        padding: '0px',
        marginLeft: '8px',
        marginRight: '8px',
        width: '20px',
    }),
})

function EditItem({ vid, dispatch, item }) {
    const theme = useContext(ThemeContext);
    const { register, handleSubmit, reset, control, formState: { errors }} = useForm()
    const [unixDate, setUnixDate] = useState()
    const [unixDueDate, setUnixDueDate] = useState()
    const [unixIssueDate, setUnixIssueDate] = useState()
    const [checkbox, setCheckbox] = useState(false)
    const [timelineData, setTimelineData] = useState({
        category: '',
        date: '',
        nodeId: '',
        ref: '',
        title: '',
        type: '',
        upcoming: false,
    })

    useEffect(() => {
        if (item.due_date) {
            const date = new Date(item.due_date * 1000)
            setUnixDueDate(date.toISOString().substr(0, 10))
        }
    }, [item])
    useEffect(() => {
        if (item.date) {
            const date = new Date(item.date * 1000)
            setUnixDate(date.toISOString().substr(0, 10))
        }
    }, [item])
    useEffect(() => {
        if (item.issue_date) {
            const date = new Date(item.issue_date * 1000)
            setUnixIssueDate(date.toISOString().substr(0, 10))
        }
    }, [item]);
    const close = (e) => {
        dispatch(closeModal())
    }
    useEffect(() => {
        reset({
            category: item.category || '',
            date: unixDate|| '',
            description: item.description || '',
            due_date: unixDueDate|| '',
            id: item.id || '',
            issue_date: unixIssueDate|| '',
            percentage: item.percentage || '',
            status: item.status || '',
            restorer: item.restorer || '',
            title: item.title || '',
            total: item.total || '',
            currency: item.currency || '',
            upload: item.upload || '',
        })
    }, [item, unixIssueDate, unixDueDate, unixDate])

    // States
    const uploadCategory = item.docType
    const [universalData, setUniversalData] = useState({
        description: item.description,
        id: item.id,
        title: item.title,
        vid,
    })
    const [selectData, setSelectData] = useState({
        restorer: item.restorer || '',
        category: item.category || '',
        percentage: item.percentage || '',
        status: item.status || '',
        currency: item.currency || '',
    })
    const [extraData, setExtraData] = useState({
        issue_date: item.issue_date || '',
        status: item.status || '',
        due_date: item.due_date || '',
        total: item.total || '',
    })
    const [invoiceData, setInvoiceData] = useState({
        status: item.status || '',
    })
    const [galleryData, setGalleryData] = useState({
        date: item.date || '',
        status: item.status || '',
    })

    const [restorers, setRestorers] = useState([])
    const [issueDate, setIssueDate] = useState({})
    const [dueDate, setDueDate] = useState({})
    const [date, setDate] = useState({})

    useEffect(() => {
        async function getRestorers() {
            const q = await query(collection(db, 'restorers'))
            const querySnapshot = await getDocs(q);
            const output = []
            querySnapshot.forEach((doc) => {
                output.push(doc.data())
            })
            setRestorers(output)
        }
        getRestorers();
    }, [])


    // Event handlers
    const handleUniChange = (e, type) => {
        setUniversalData({ ...universalData, [type]: e.target.value })
    }
    const handleSelectChange = (e, type) => {
        setSelectData({ ...selectData, [type]: e.value })
    }
    const handleExtraChange = (e, type) => {
        if (type === 'date') {
            const date = parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))
            setDate({ date: date })
        } else if (type === 'issue_date') {
            const issueDate = parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))
            setIssueDate({ issue_date: issueDate })
        } else if (type === 'due_date') {
            const dueDate = parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0))
            setDueDate({ due_date: dueDate })
        } else {
            setExtraData({ ...extraData, [type]: e.target.value })
        }
    }
    const handleGalleryChange = (e, type) => {
        setGalleryData({ ...galleryData, [type]: e.target.value })
    }
    const handleInvoiceChange = (e, type) => {
        setInvoiceData({ ...invoiceData, [type]: e.value })
    }
    const toggle = () => {
        const isChecked = !checkbox
        setCheckbox(isChecked)
        let date = Date.now()
        date = date.toString().substring(0, 10)
        date = parseInt(date)
        setTimelineData({
            ...timelineData,
            category: universalData.category || '',
            nodeId: universalData.id,
            title: universalData.title,
            date: date,
            type: uploadCategory === 'gallery' ? 'gallery' : uploadCategory + 's',
        })
    }
    const addTimelineNode = async (docId) => {
        const nodeData = { ...timelineData }
        nodeData.ref = docId
        const fetchTimeline = collection(db, 'timelines')
        const q = query(fetchTimeline, where('vid', '==', vid))
        const querySnapshot = await getDocs(q);
        let result = []
        querySnapshot.forEach((doc) => {
            result = doc.data()
        })
        const timelineRef = doc(db, 'timelines', result.id)
        updateDoc(timelineRef, {
            nodes: arrayUnion(nodeData),
        })
    }
    const handleFormSubmit = async (e) => {
        let docRef = ''
        switch (uploadCategory) {
        case 'invoice':
            {
                const uploadData = { ...universalData, ...selectData, ...extraData, ...invoiceData, ...issueDate, ...dueDate }
                uploadData.docId = item.docId
                docRef = doc(db, 'invoices', item.docId)
                await updateDoc(docRef, uploadData);
                if (checkbox) {
                    addTimelineNode(uploadData.docId)
                }
            }
            break
        case 'quote':
            {
                const uploadData = { ...universalData, ...selectData, ...extraData, ...issueDate, ...dueDate }
                uploadData.docId = item.docId
                docRef = doc(db, 'quotes', item.docId)
                await updateDoc(docRef, uploadData);
                if (checkbox) {
                    addTimelineNode(uploadData.docId)
                }
            }
            break
        case 'gallery':
            {
                const uploadData = { ...universalData, ...selectData, ...extraData, ...galleryData, ...date }
                uploadData.docId = item.docId
                docRef = doc(db, 'gallery', item.docId)
                await updateDoc(docRef, uploadData);
                if (checkbox) {
                    addTimelineNode(uploadData.docId)
                }
            }
            break
        }
        close()
    }
    const statusArray = ['part-paid', 'overdue', 'paid', 'pending']

    if (statusArray.includes(item.status)) {
        statusArray.splice(statusArray.indexOf(item.status), 1)
    }
    const capitaliseFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    let placeholder = capitaliseFirstLetter(uploadCategory) + ' title'
    placeholder = (placeholder !== 'Gallery title') ? placeholder : 'Entry title'

    // Restorers Placeholder

    const [restorerPlaceholder, setRestorerPlaceholder] = useState([])
    const currentRestorer = restorers.filter((restorer) => restorer.id === item.restorer)[0]
    useEffect(() => {
        setRestorerPlaceholder(currentRestorer && currentRestorer.id ? `${currentRestorer.name}` : 'Restorer')
    }, [restorers])

    // Setting the fields
    let output = null
    switch (uploadCategory) {
    case 'gallery':
        output = <>
            <Box mt='2' mb='xs'>
                <Input {...register('status', { required: 'This field is required' })} md='sm' id='data-status' name='status' placeholder=' Status eg "Left the workshop"' onChange={(e) => handleGalleryChange(e, 'status')} />
                {errors.status && (
                    <Text color='red'>
                        This field is required
                    </Text>
                )}</Box>
            <Flex>
                <Box mb='sm' width='50%'>
                    <Text as='p' variant='body' mb='xs'>
                        Date
                    </Text>
                    <Input {...register('date', { required: 'This field is required' })} defaultValue={unixDate} type='date' onChange={(e) => handleExtraChange(e, 'date')} />
                    {errors.due_date && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
                <Box ml='xxs' mt='md' width='50%'>
                    <Controller
                        name='restorer'
                        id='data-restorer'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        render={({ field: { onChange, name }}) => (
                            <Select
                                name={name}
                                styles={customSelect(theme)}
                                placeholder={restorerPlaceholder || 'Restorer'}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelectChange(e, 'restorer')
                                }}
                                options={restorers.map((restorer) => {
                                    return {
                                        value: restorer.id,
                                        label: restorer.name,
                                        key: restorer.id,
                                    }
                                })}
                            />
                        )}
                    />
                    {errors.restorer && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
            </Flex>
        </>

        break
    case 'invoice':
        output = <>
            <Flex my='xs'>
                <Box mr='xxs' width='50%'>
                    <Input {...register('id', { required: 'This field is required' })} id='data-id' name='id' placeholder={item.id || 'Entry ID'} onChange={(e) => handleUniChange(e, 'id')}/>
                    {errors.id && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
                <Box ml='xxs' width='50%'>
                    <Controller
                        name='restorer'
                        id='data-restorer'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        render={({ field: { onChange, name }}) => (
                            <Select
                                name={name}
                                styles={customSelect(theme)}
                                placeholder={restorerPlaceholder || 'Restorer'}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelectChange(e, 'restorer')
                                }}
                                options={restorers.map((restorer) => {
                                    return {
                                        value: restorer.id,
                                        label: restorer.name,
                                        key: restorer.id,
                                    }
                                })}
                            />
                        )}
                    />
                    {errors.restorer && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
            </Flex>
            <Flex my='xs'>
                <Box mr='xxs' width='50%'>
                    <Flex>
                        <Select styles={customSelect(theme)} placeholder={item.currency || '£'} id='data-currency' name='currency' onChange={(e) => handleSelectChange(e, 'currency')} options={[
                            { label: '€', value: '€' },
                            { label: '£', value: '£' },
                            { label: '$', value: '$' },
                            { label: 'SFr.', value: 'SFr.' },
                        ]}>
                        </Select>
                        <Box>
                            <Input {...register('total', { required: 'This field is required' })} id='data-total' defaultValue={item.total} placeholder='Total' onChange={(e) => handleExtraChange(e, 'total')}/>
                        </Box>
                    </Flex>
                    {errors.total && (
                        <Text color='red'>
                            These fields are required
                        </Text>
                    )}
                </Box>
                <Box ml='xxs' width='50%'>
                    <Controller
                        name='percentage'
                        id='data-percentage'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        render={({ field: { onChange, name }}) => (
                            <Select
                                name={name}
                                styles={customSelect(theme)}
                                placeholder={`${item.percentage}%` || 'Completion %'}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelectChange(e, 'percentage')
                                }}
                                options={[
                                    { label: '0%', value: '0' },
                                    { label: '25%', value: '25' },
                                    { label: '50%', value: '50' },
                                    { label: '75%', value: '75' },
                                    { label: '100%', value: '100' },
                                ]}
                            />
                        )}
                    />
                    {errors.percentage && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
            </Flex>
            <Flex my='xs'>
                <Box mr='xxs' width='50%'>
                    <Text as='p' variant='body' mb='xs'>
                        Issue Date
                    </Text>
                    <Input {...register('issue_date', { required: 'This field is required' })} type='date' onChange={(e) => handleExtraChange(e, 'issue_date')} />
                    {errors.issue_date && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
                <Box ml='xxs' width='50%'>
                    <Text as='p' variant='body' mb='xs'>
                        Due Date
                    </Text>
                    <Input {...register('due_date', { required: 'This field is required' })} type='date' onChange={(e) => handleExtraChange(e, 'due_date')} />
                    {errors.due_date && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
            </Flex>
            <Box my='xs'>
                <Select styles={customSelect(theme)} sx={{ cursor: 'pointer' }} {...register('status', { required: 'This field is required' })} id='data-status' name='status' onChange={(e) => handleInvoiceChange(e, 'status')} placeholder={capitaliseFirstLetter(item.status) || 'Status'} options={statusArray.map((status, index) => {
                    return {
                        value: status,
                        label: capitaliseFirstLetter(status),
                        key: index,
                    }
                })}>
                </Select>
            </Box>
        </>
        break
    case 'quote':
        output = <>
            <Flex my='xs'>
                <Box mr='xxs' width='50%'>
                    <Input {...register('id', { required: 'This field is required' })} id='data-id' name='id' placeholder={item.id || 'Entry ID'} onChange={(e) => handleUniChange(e, 'id')}/>
                    {errors.id && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
                <Box ml='xxs' width='50%'>
                    <Controller
                        name='restorer'
                        id='data-restorer'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        render={({ field: { onChange, name }}) => (
                            <Select
                                name={name}
                                styles={customSelect(theme)}
                                placeholder={restorerPlaceholder || 'Restorer'}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelectChange(e, 'restorer')
                                }}
                                options={restorers.map((restorer) => {
                                    return {
                                        value: restorer.id,
                                        label: restorer.name,
                                        key: restorer.id,
                                    }
                                })}
                            />
                        )}
                    />
                    {errors.restorer && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
            </Flex>
            <Flex my='xs'>
                <Box mr='xxs' width='50%'>
                    <Text as='p' variant='body' mb='xs'>
                        Issue Date
                    </Text>
                    <Input {...register('issue_date', { required: 'This field is required' })} type='date' defaultValue={unixDueDate} onChange={(e) => handleExtraChange(e, 'issue_date')} />
                    {errors.issue_date && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
                <Box ml='xxs' width='50%'>
                    <Text as='p' variant='body' mb='xs'>
                        Due Date
                    </Text>
                    <Input {...register('due_date', { required: 'This field is required' })} type='date' defaultValue={unixIssueDate} onChange={(e) => handleExtraChange(e, 'due_date')} />
                    {errors.due_date && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
            </Flex>
            <Box my='xs' pr='xxs' width='50%'>
                <Flex>
                    <Select styles={customSelect(theme)} placeholder={item.currency ||'€'} id='data-currency' name='currency' onChange={(e) => handleSelectChange(e, 'currency')} options={[
                        { label: '€', value: '€' },
                        { label: '£', value: '£' },
                        { label: '$', value: '$' },
                        { label: 'SFr.', value: 'SFr.' },
                    ]}>
                    </Select>
                    <Box width='100%'>
                        <Input {...register('total', { required: 'This field is required' })} id='data-total' name='total' placeholder='Total' onChange={(e) => handleExtraChange(e, 'total')} />
                    </Box>
                </Flex>
                {errors.total && (
                    <Text color='red'>
                        These fields are required
                    </Text>
                )}
            </Box>
        </>
    }

    return (
        <Box as='form' width={['auto', 'auto', 'auto', 'auto', '399px']} onSubmit={handleSubmit((data) => {
            handleFormSubmit(data)
        })}>
            <Flex flexDirection='column'>
                <Box my='xs'>
                    <Controller
                        name='category'
                        id='data-category'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        render={({ field: { onChange, name }}) => (
                            <Select
                                name={name}
                                styles={customSelect(theme)}
                                placeholder={capitaliseFirstLetter(item.category) ||'Category'}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelectChange(e, 'category')
                                }}
                                options={[
                                    { label: 'Mechanical', value: 'mechanical' },
                                    { label: 'Upholstery', value: 'upholstery' },
                                    { label: 'Coachwork', value: 'coachwork' },
                                    { label: 'Electrical', value: 'electrical' },
                                ]}
                            />
                        )}
                    />
                    {errors.category && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
                <Box my='xs'>
                    <Input {...register('title', { required: 'This field is required' })} id='data-title' name='title' defaultValue={item.title || placeholder} onChange={(e) => handleUniChange(e, 'title')} />
                    {errors.title && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
                {output}
                <Box my='xs'>
                    <Textarea {...register('description', { required: 'This field is required' })} height={['auto', '169px']} id='data-description' name='description' defaultValue={item.description || 'Description'} onChange={(e) => handleUniChange(e, 'description')} />
                    {errors.description && (
                        <Text color='red'>
                            This field is required
                        </Text>
                    )}
                </Box>
                <Flex width='100' my='xs' p='xxs' sx={{ border: '1px solid #77B7BE' }}>
                    <Label color='white'>
                        <Flex alignItems='center'>
                            <Checkbox {...register('upload')} id='data-upload' name='upload' sx={{ cursor: 'pointer' }} onChange={(e) => toggle()}/>
                            Upload to timeline
                        </Flex>
                    </Label>
                </Flex>
                <Flex alignItems='center'>
                    <Button mt='md' variant='primary' bg='greyDark' width='50%'>Update</Button>
                    <Box mt='4'>
                        <Link to='/' onClick={close}>
                            <Text mx='sm' color='grey' sx={{ textDecoration: 'underline', cursor: 'pointer' }}>Cancel</Text>
                        </Link>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
}

// TODO: Add post options

function mapStateToProps(state) {
    return {
        vid: state.Vehicle.vid,
        media: state.Upload,
        item: state.Modal.item,
    };
}

export default connect(mapStateToProps)(EditItem);

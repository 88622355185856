import React from 'react';
import { connect } from 'react-redux'
import { Box, Flex, Button, Text } from 'rebass/styled-components'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { db } from '../system/firebase/index'
import { collection, doc, getDocs, updateDoc, query, where, deleteDoc } from 'firebase/firestore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { closeModal } from '../system/redux/reducers/modal'
import { navigate } from 'gatsby'
import EditItem from '../components/general/EditItem'


const ModalBackground = styled(Flex)`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index:1000;
    `

function ConfirmModal({ modal, dispatch, data }) {
    const close = (e) => {
        dispatch(closeModal())
    }
    const handleConfirm = (e) => {
        const docRef = doc(db, 'quotes', modal.id)
        updateDoc(docRef, {
            status: 'approved',
        })
        dispatch(closeModal())
    }
    const handleReject = (e) => {
        const docRef = doc(db, 'quotes', modal.id)
        updateDoc(docRef, {
            status: 'rejected',
        })
        dispatch(closeModal())
    }
    const logout = () => {
        dispatch(closeModal())
        navigate(`/logout`)
    }
    const deleteItem = async () => {
        let category = null
        if (modal.item.docType === 'invoice') {
            category = 'invoices'
        } else if (modal.item.docType === 'quote') {
            category = 'quotes'
        } else if (modal.item.docType === 'gallery') {
            category = 'gallery'
        } else if (modal.item.docType === 'restorers') {
            category = 'restorers'
        } else {
            category = 'cars'
        }
        let docId = null
        let q = null
        if (category === 'cars') {
            q = query(collection(db, 'cars'), where('vid', '==', modal.item.vid))
        } else if (category === 'restorers') {
            q = query(collection(db, category), where('id', '==', modal.item.id))
        } else {
            q = query(collection(db, category), where('id', '==', modal.item.id), where('vid', '==', modal.item.vid))
        }
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach( (doc) => {
            docId = doc.id
        });
        await deleteDoc(doc(db, category, docId))
        dispatch(closeModal())
    }

    let output = null
    let action = null
    switch (modal.confirm) {
    case 'approve':
        output = <Text as='h2' variant='h2'>Are you sure you want to approve this quote?</Text>
        action = <Button mx='xs' onClick={handleConfirm}>Confirm</Button>
        break;
    case 'reject':
        output = <Text as='h2' variant='h2'>Are you sure you want to reject this quote?</Text>
        action = <Button mx='xs' onClick={handleReject}>Confirm</Button>

        break;
    case 'logout':
        output = <Text as='h2' variant='h2'>Are you sure you want to logout?</Text>
        action = <Button mx='xs' onClick={logout}>Confirm</Button>
        break
    case 'edit':
        output = <EditItem item={modal.item} />
        break
    default:
        output = <Text as='h2' variant='h2'>Are you sure you want to delete this item?</Text>
        action = <Button mx='xs' onClick={deleteItem}>Confirm</Button>

        break;
    }

    if (!modal.isOpen) {
        return null
    } else {
        return (
            <ModalBackground onClick={close}>
                <Flex p={['xs', 'xs', 'xs', 'xs', 'sm']} width={['100%', '100%', '100%', '100%', 'auto']} maxWidth='700px' minHeight='220px' maxHeight='850px' height={modal.confirm === 'edit' ? '100%' : '12%'} bg='charcoal' flexDirection='column' sx={{ overflow: 'auto' }} onClick={(e) => e.stopPropagation()}>
                    <Flex justifyContent='space-between' fontSize='27px' mx='sm' onClick={close}>
                        <Text variant='h3'>{
                            modal.confirm !== 'edit' ? null : 'Edit Item'
                        }</Text>
                        <Box alignSelf='flex-end'>
                            <Link to='/' onClick={(e) => e.preventDefault()}>
                                <Box color='white'>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </Box>
                            </Link>
                        </Box>
                    </Flex>
                    <Box m='sm'>
                        {output}
                    </Box>
                    <Flex alignItems='center' justifyContent='center' height='200px'>
                        <Box>
                            {action}
                        </Box>
                        {
                            modal.confirm !== 'edit' ?
                                <Box>
                                    <Link to='/' onClick={(e) => e.preventDefault()}>
                                        <Text mx='sm' color='grey' sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={close}>Cancel</Text>
                                    </Link>
                                </Box> :
                                null
                        }
                    </Flex>
                </Flex>
            </ModalBackground>
        );
    }
}

function mapStateToProps(state) {
    return {
        modal: state.Modal,
    };
}

export default connect(mapStateToProps)(ConfirmModal)

import React from 'react'
import { Box, Text, Flex, Link as ExternalLink } from 'rebass/styled-components'
import { Link } from 'gatsby'
import Brandmark from '../assets/icons/brandmark-footer.svg'
import { connect } from 'react-redux'
import ConfirmModal from '../modules/ConfirmModal'
import { openConfirmModal } from '../system/redux/reducers/modal'

const Footer = ({ dispatch }) => {
    const handleClick = (e, type) => {
        e.preventDefault()
        // as in, what are we confirming?
        const confirm = { confirm: 'logout' }

        dispatch(openConfirmModal(confirm))
    }
    const isSSR = typeof window === 'undefined'
    return (
        <>
            {!isSSR && (
                <Flex
                    bg='aquaMedium'
                    flexDirection={[
                        'column',
                        'column',
                        'column',
                        'column',
                        'row',
                    ]}
                    height={['230px', '230px', '230px', '230px', '142px']}
                    justifyContent='center'
                    alignItmes='center'
                    px='sm'
                >
                    <Flex
                        alignItems={[
                            'flex-start',
                            'flex-start',
                            'flex-start',
                            'flex-start',
                            'center',
                        ]}
                        flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'row',
                        ]}
                        height={['230px', '230px', '230px', '230px', '142px']}
                        justifyContent={[
                            'flex-start',
                            'flex-start',
                            'flex-start',
                            'flex-start',
                            'center',
                        ]}
                    >
                        <Box
                            py='sm'
                            width='100%'
                            sx={{
                                borderBottom: [
                                    '1px solid black',
                                    '1px solid black',
                                    '1px solid black',
                                    '1px solid black',
                                    '0',
                                ],
                            }}
                        >
                            <Box
                                mr='md'
                                width={[
                                    '220px',
                                    '220px',
                                    '220px',
                                    '220px',
                                    '270px',
                                ]}
                            >
                                <Brandmark />
                            </Box>
                        </Box>
                        <Box
                            width={['100%', '100%', '100%', '100%', 'auto']}
                            py='sm'
                            mx={['xs', 'xs', 'xs', 'xs', 'md']}
                        >
                            <Text as='p' variant='body' color='black'>
                                Take me to
                            </Text>
                            <Flex justifyContent='space-between'>
                                <ExternalLink
                                    href='https://kidston.com'
                                    target='_blank'
                                >
                                    <Text
                                        as='h2'
                                        fontSize='28px'
                                        fontFamily='heading'
                                        color='black'
                                    >
                                        kidston.com
                                    </Text>
                                </ExternalLink>
                                <Box
                                    mx='sm'
                                    display={[
                                        'flex',
                                        'flex',
                                        'flex',
                                        'flex',
                                        'none',
                                    ]}
                                    flexBasis='50%'
                                    alignItems='flex-end'
                                    justifyContent='flex-end'
                                    flexDirection='column'
                                >
                                    <Link to='/' onClick={handleClick}>
                                        <Text
                                            fontFamily='body'
                                            my='xxs'
                                            color='black'
                                        >
                                            <strong>Log out</strong>
                                        </Text>
                                    </Link>
                                </Box>
                            </Flex>
                            <Text
                                as='p'
                                variant='body'
                                color='black'
                                fontSize='10px'
                            >
                                {' '}
                                © {new Date().getFullYear()} Kidston
                            </Text>
                        </Box>
                    </Flex>
                    <Box
                        display={['none', 'none', 'none', 'none', 'flex']}
                        flexBasis='50%'
                        alignItems='flex-end'
                        justifyContent='centre'
                        flexDirection='column'
                        mx='md'
                    >
                        <Link to='/privacy'>
                            <Text
                                fontFamily='body'
                                mt='md'
                                mb='xxs'
                                color='black'
                                css={{
                                    '&:hover': {
                                        color: 'white',
                                    },
                                }}
                            >
                                Privacy Policy
                            </Text>
                        </Link>
                        <Link to='/terms'>
                            <Text
                                fontFamily='body'
                                my='xxs'
                                color='black'
                                css={{
                                    '&:hover': {
                                        color: 'white',
                                    },
                                }}
                            >
                                Terms and Conditions
                            </Text>
                        </Link>
                        <Link to='/' onClick={handleClick}>
                            <Text
                                fontFamily='body'
                                my='xxs'
                                color='black'
                                css={{
                                    '&:hover': {
                                        color: 'white',
                                    },
                                }}
                            >
                                <strong>Log out</strong>
                            </Text>
                        </Link>
                    </Box>
                    <ConfirmModal />
                </Flex>
            )}
        </>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(Footer)
